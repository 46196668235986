import React, { useCallback, useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Typography,
  Box,
  styled,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  IconButtonProps,
  Link,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Sidebar from '../../components/navbar/sidebar'
import 'react-datepicker/dist/react-datepicker.css'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import { ReactComponent as PdfIcon } from '../../assets/images/pdf.svg'
import { ReactComponent as DownloadIcon } from '../../assets/images/download.svg'
import { ConsumerRequestOffer, DiagnosticReport } from './models/diagnostic_report'
import { formatDateTime } from '../home/home'
import DiagnosticReportModal from './modals/diagnostic_report'
import socket from '../../sockets/socket'
import { apiCall } from '../../components/api/api'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

interface StatusButtonProps extends IconButtonProps {
  status: string
}

const StatusButton = styled(IconButton)<StatusButtonProps>(({ theme, status }) => ({
  borderRadius: '20px',
  color: status === 'approved' ? '#067647' : status === 'waiting' ? '#B54708' : '#344054',
  padding: theme.spacing(1, 2),
  fontSize: '0.875rem',
  fontWeight: 'bold',
  textTransform: 'none',
  border: '1px solid transparent',
  marginLeft: 'auto',
  width: 'fit-content',
  '&:hover': {
    backgroundColor:
      status === 'approved' ? '#ABEFC6' : status === 'waiting' ? '#FEDF89' : '#EAECF0',
    border: '1px solid #fff',
  },
  ...(status === 'approved' && {
    backgroundColor: '#ECFDF3',
    border: '1px solid #ABEFC6',
  }),
  ...(status === 'waiting' && {
    backgroundColor: '#FFFAEB',
    border: '1px solid #FEDF89',
  }),
  ...(status === 'rejected' && {
    backgroundColor: '#F9FAFB',
    border: '1px solid #EAECF0',
  }),
}))

const DiagnosticReportPage: React.FC = () => {
  const [data, setData] = useState<DiagnosticReport[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [totalRows, setTotalRows] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedOffer, setSelectedOffer] = useState<ConsumerRequestOffer | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const fetchData = useCallback(async () => {
    try {
      const response = await apiCall(
        `admin/offer-diagnostic-report?limit=${rowsPerPage}&page=${
          page + 1
        }&search=${encodeURIComponent(searchTerm)}`,
        'get'
      )
      setData(response.items)
      setTotalRows(response.count)
    } catch (error) {
      console.error('Failed to fetch data:', error)
    }
  }, [page, rowsPerPage, searchTerm])

  useEffect(() => {
    fetchData()

    socket.on('new-diagnostic-file', fetchData)

    return () => {
      socket.off('new-diagnostic-file', fetchData)
    }
  }, [fetchData])

  const fetchReportDetails = async (offerId: string) => {
    const url = `admin/offer-diagnostic-report/${offerId}`
    try {
      const response = await apiCall(url, 'get')
      setSelectedOffer(response)
      setModalOpen(true)
    } catch (error) {
      console.error('Failed to fetch report details:', error)
      enqueueSnackbar('Error fetching report details', { variant: 'error' })
    }
  }

  const handleOpenModal = (report: DiagnosticReport) => {
    if (report.is_approved === null) {
      fetchReportDetails(report.consumer_request_offer_id)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Container
      maxWidth={false}
      style={{
        maxWidth: '90%',
        display: 'flex',
        marginLeft: '100px',
        marginBottom: '30px',
        marginTop: '30px',
      }}
    >
      <SnackbarProvider maxSnack={5}>
        <Sidebar />
      </SnackbarProvider>
      <Box
        style={{
          flex: 1,
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography variant="h4" style={{ marginBottom: '20px' }}>
            Diagnostic reports & Recommended services
          </Typography>
        </Box>
        <TextField
          label="Search for shops"
          variant="outlined"
          color="warning"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: 400,
            marginBottom: '20px',
            backgroundColor: '#fff',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'black',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'orange',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Offer ID</StyledTableCell>
                  <StyledTableCell>Vehicle ID</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell>Shop name</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Received on</StyledTableCell>
                  <StyledTableCell>Diagnostic report by shop</StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((report) => {
                  return (
                    <StyledTableRow key={report.consumer_request_offer_id}>
                      <StyledTableCell>{report.offer.consumer_request.slug}</StyledTableCell>
                      <StyledTableCell>
                        {report.offer.consumer_request.vehicle_name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {report.offer.consumer_request.location_name}
                      </StyledTableCell>
                      <StyledTableCell>{report.offer.provider_branch.name}</StyledTableCell>
                      <StyledTableCell>
                        {report.type === 'ESTIMATE' ? 'Diagnostic report' : 'Recommended services'}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDateTime(report.updated_at ?? report.created_at)}
                      </StyledTableCell>
                      <StyledTableCell style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box display="flex" flexDirection="column">
                          <Box display="flex" alignItems="center" mb={1}>
                            <PdfIcon />
                            <Typography sx={{ ml: 1 }}>{report.name}</Typography>
                          </Box>
                          {report.pmi_file && (
                            <Box display="flex" alignItems="center" mb={1} mt={2}>
                              <PdfIcon />
                              <Typography sx={{ ml: 1 }}>{report.pmi_file?.name}</Typography>
                            </Box>
                          )}
                          {report.inspection_file && (
                            <Box display="flex" alignItems="center" mb={1} mt={2}>
                              <PdfIcon />
                              <Typography sx={{ ml: 1 }}>{report.inspection_file?.name}</Typography>
                            </Box>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" flexDirection="column">
                          <Box display="flex" alignItems="center" mb={1}>
                            <Link
                              href={report.url}
                              download
                              alignItems="center"
                              target="_blank"
                              sx={{
                                ml: 'auto',
                                fontWeight: 'bold',
                                color: '#DB5D08',
                                textDecoration: 'none',
                              }}
                            >
                              <DownloadIcon /> Download
                            </Link>
                          </Box>
                          {report.pmi_file && (
                            <Box display="flex" alignItems="center" mb={1} mt={3}>
                              <Link
                                href={report.pmi_file?.url}
                                download
                                alignItems="center"
                                target="_blank"
                                sx={{
                                  ml: 'auto',
                                  fontWeight: 'bold',
                                  color: '#DB5D08',
                                  textDecoration: 'none',
                                }}
                              >
                                <DownloadIcon /> Download
                              </Link>
                            </Box>
                          )}
                          {report.inspection_file && (
                            <Box display="flex" alignItems="center" mb={1} mt={3}>
                              <Link
                                href={report.inspection_file?.url}
                                download
                                alignItems="center"
                                target="_blank"
                                sx={{
                                  ml: 'auto',
                                  fontWeight: 'bold',
                                  color: '#DB5D08',
                                  textDecoration: 'none',
                                }}
                              >
                                <DownloadIcon /> Download
                              </Link>
                            </Box>
                          )}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <StatusButton
                          status={
                            report.is_approved
                              ? 'approved'
                              : report.is_approved === null
                                ? 'waiting'
                                : 'rejected'
                          }
                          sx={{
                            cursor: report.is_approved === null ? 'pointer' : 'default',
                            pointerEvents: report.is_approved === null ? 'auto' : 'none',
                          }}
                          onClick={() => handleOpenModal(report)}
                        >
                          {report.is_approved
                            ? 'Approved'
                            : report.is_approved === null
                              ? 'Waiting for approval'
                              : 'Sent back to shop'}
                        </StatusButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <DiagnosticReportModal
            open={modalOpen}
            onClose={handleCloseModal}
            offer={selectedOffer}
          />
          <TablePagination
            component="div"
            count={totalRows}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  )
}

export default DiagnosticReportPage
